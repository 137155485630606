import { useContext, useEffect, useRef, useState } from "react";
import { CouponCrudApi, ProductCrudApi } from "../../client/EntityApi";
import DefaultBreadcrumb from "../../components/common/DefaultBreadcrumb";
import CrudTable, {
  ICrudTableRefMethods,
} from "../../components/crud/CrudTable";
import CrudTableProcessButton from "../../components/crud/CrudTableProcessButton";
import {
  getCouponCPColumns,
  getCouponCPFormItems,
} from "../../components/hook/CouponCPComponent";
import CouponAddModal, {
  ICouponAddModalRefMethods,
} from "../../components/modal/CouponAddModal";
import { PlusOutlined } from "@ant-design/icons";
import { isAdmin } from "../../helpers/AuthHelper";
import dayjs from "dayjs";
import CustomCard from "../../components/card/CustomCard";
import {
  getConstantValue,
  getOptionsFromEntities,
} from "../../helpers/UtilHelper";
import { ConstantContext } from "../../contexts";
import { Tag } from "antd";

const CouponCrudPage = () => {
  const constants = useContext(ConstantContext);
  const couponAddModalRef = useRef<ICouponAddModalRefMethods>(null);
  const crudTableRef = useRef<ICrudTableRefMethods>(null);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    ProductCrudApi.getAll(false).then((response) => {
      setProductOptions(getOptionsFromEntities(response["hydra:member"]));
    });
  }, []);

  return (
    <>
      <DefaultBreadcrumb items={[{ title: "Kupon Kodu Listesi" }]} />
      <CustomCard>
        <CrudTable
          isCouponTable
          ref={crudTableRef}
          entityLabel="Kupon Kodu"
          api={CouponCrudApi}
          actionsColumnFixed="right"
          columns={getCouponCPColumns(constants)}
          editFormItems={getCouponCPFormItems(
            productOptions,
            constants,
            crudTableRef.current?.editFormInstance,
            true
          )}
          hideAddProcess
          hideEditProcess={!isAdmin()}
          hideDeleteProcess={(row) => !isAdmin() || row.recordCount > 0}
          setEditFields={(row) => ({
            startDate: row.startDate ? dayjs(row.startDate) : null,
            finishDate: row.finishDate ? dayjs(row.finishDate) : null,
            product: row.product.map((product: any) => product["@id"]),
            labels: row.labels.map((label: any) => ({
              label: label.name,
              value: label["@id"],
            })),
          })}
          beforeEditOperation={(values) => ({
            ...values,
            labels: values.labels.map((label: any) =>
              typeof label === "object" ? label["value"] : label
            ),
          })}
          expandable={{
            expandedRowRender: (record) => (
              <p>
                <b>No:</b> {record.id}
                <br />
                <b>Tekrar Kullanım Tipi:</b>{" "}
                {getConstantValue(
                  record.againUseType,
                  constants?.againUseTypes
                )}
                {record.againUseType === "period" && (
                  <>
                    <br />
                    <b>Tekrar Kullanım Değeri:</b> {record.againUseValue} Ay
                  </>
                )}
                <br />
                <b>Satın alma süre limitlerini kaldır:</b>{" "}
                {getConstantValue(
                  record.isLimitPassive ? 1 : 0,
                  constants?.yesNo
                )}
                <br />
                <b>Hangi Paketlerde Kullanılamasın:</b>{" "}
                {record.productPeriods?.map((productPeriod: any) => (
                  <Tag>
                    {getConstantValue(productPeriod, constants?.productPeriods)}
                  </Tag>
                ))}
              </p>
            ),
          }}
          extraTitleProcess={() =>
            isAdmin() && (
              <CrudTableProcessButton
                tooltipText="Kupon Ekle"
                icon={<PlusOutlined />}
                onClick={() => couponAddModalRef.current?.setOpen(true)}
              />
            )
          }
        />
      </CustomCard>
      <CouponAddModal
        ref={couponAddModalRef}
        afterCreate={() => crudTableRef.current?.refreshData()}
      />
    </>
  );
};

export default CouponCrudPage;
