import { Form, InputNumber, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { required } from "../../helpers/FormRuleHelper";
import { useContext } from "react";
import { ConstantContext } from "../../contexts";

interface ICouponIsGiftFormProps {
  form?: FormInstance;
}

const CouponIsGiftForm = (props: ICouponIsGiftFormProps) => {
  const constants = useContext(ConstantContext);
  const isGift = Form.useWatch("isGift", props.form);

  return (
    <>
      <Form.Item
        name="isGift"
        label="Hediye mi?"
        rules={[required]}
        initialValue={false}
      >
        <Select
          options={[
            { label: "Evet", value: true },
            { label: "Hayır", value: false },
          ]}
        />
      </Form.Item>
      {!isGift && (
        <>
          <Form.Item
            name="discountType"
            label="İndirim Tipi"
            rules={[required]}
            initialValue="amount"
          >
            <Select options={constants?.paymentTypes} />
          </Form.Item>
          <Form.Item
            name="discountValue"
            label="İndirim Tutarı"
            rules={[required]}
            initialValue="0"
          >
            <InputNumber
              stringMode
              precision={2}
              min={0}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default CouponIsGiftForm;
