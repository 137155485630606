import { useContext } from "react";
import { RecordCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/crud/CrudTable";
import { getRecordCPColumns } from "../../components/hook/RecordCPComponent";
import { ConstantContext } from "../../contexts";
import DefaultBreadcrumb from "../../components/common/DefaultBreadcrumb";
import CustomCard from "../../components/card/CustomCard";
import { isAdmin } from "../../helpers/AuthHelper";

const RecordCrudPage = () => {
  const constantContext = useContext(ConstantContext);

  return (
    <>
      <DefaultBreadcrumb items={[{ title: "Kayıt Listesi" }]} />
      <CustomCard>
        <CrudTable
          entityLabel="Kayıt"
          api={RecordCrudApi}
          columns={getRecordCPColumns(constantContext?.recordStates)}
          hideAddProcess
          hideDefaultRowProceses
          expandable={{
            expandedRowRender: (record) => (
              <>
                <p>
                  <b>Hakediş Detayı:</b> {record.paymentLog}
                </p>
                {isAdmin() && (
                  <p>
                    <b>Sentiment Kullanıcı ID:</b> {record.sentimentUserId}
                    <br />
                    <b>IP Adresi</b> {record.ip}
                  </p>
                )}
              </>
            ),
            rowExpandable: (record) => record.paymentLog !== undefined,
          }}
        />
      </CustomCard>
    </>
  );
};

export default RecordCrudPage;
