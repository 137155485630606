import {
  Checkbox,
  Dropdown,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { isAdmin } from "../../helpers/AuthHelper";
import {
  getFormattedDate,
  getOptionsFromEntities,
} from "../../helpers/UtilHelper";
import ZDatePicker from "../common/ZDatePicker";
import { max, min, required } from "../../helpers/FormRuleHelper";
import SearchSelect from "../common/SearchSelect";
import { LabelCrudApi } from "../../client/EntityApi";
import CouponAgainUseTypeForm from "../form/CouponAgainUseTypeForm";
import CouponIsGiftForm from "../form/CouponIsGiftForm";

export const getCouponCPColumns = (constants: any) => {
  let columns = [
    { title: "No", dataIndex: "id", key: "id", hidden: true },
    {
      title: "Kupon Kodu",
      dataIndex: "code",
      key: "code",
      fixed: true,
      render: (value: string) => (
        <Tooltip title="Kopyalamak için tıklayınız.">
          <Tag
            style={{ height: 32, cursor: "pointer" }}
            onClick={() => {
              message.success("Panoya kopyalandı: " + value);
              navigator.clipboard.writeText(
                process.env.REACT_APP_REDIRECT_URL + value
              );
            }}
          >
            <Row style={{ height: "100%" }} justify="center" align="middle">
              {value}
            </Row>
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: "Kullanılabilir Mi ?",
      dataIndex: "isUseable",
      key: "isUseable",
      filters: constants?.yesNo,
      filterMultiple: false,
      render: (value: boolean) => (
        <Tag color={value ? "green" : "red"}>{value ? "Evet" : "Hayır"}</Tag>
      ),
    },
    {
      title: "Kayıt Sayısı",
      dataIndex: "recordCount",
      key: "recordCount",
    },
    {
      title: "Tıklanma Sayısı",
      dataIndex: "clickCount",
      key: "clickCount",
    },
    {
      title: "Hakediş Bakiyesi",
      dataIndex: "balance",
      key: "balance",
      render: (value: any) => value + " ₺",
    },
    {
      title: "Toplam Ödeme",
      dataIndex: "totalSentimentProductPrice",
      key: "totalSentimentProductPrice",
      render: (value: any) => value + " ₺",
    },
    {
      title: "İndirim Bilgisi",
      dataIndex: "discount",
      key: "discount",
      render: (_: any, row: any) =>
        (row.discountType === "rate" ? "%" : "") +
        row.discountValue +
        (row.discountType === "amount" ? "₺" : ""),
    },
    {
      title: "Kullanım Limiti",
      dataIndex: "useLimit",
      key: "useLimit",
      sorter: true,
      render: (value: any) => value ?? "Limitsiz",
    },
    {
      title: "Kullanım Başlangıcı",
      dataIndex: "startDate",
      key: "startDate",
      render: (value: any) =>
        value ? getFormattedDate(value, "DD.MM.YYYY HH:mm") : "-",
    },
    {
      title: "Kullanım Bitişi",
      dataIndex: "finishDate",
      key: "finishDate",
      render: (value: any) =>
        value ? getFormattedDate(value, "DD.MM.YYYY HH:mm") : "-",
    },
    {
      title: "Uygulanacağı Ürünler",
      dataIndex: "product",
      key: "product",
      render: (value: any[]) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items: value.map((label, index) => ({
              key: index,
              label: label.name,
            })),
          }}
        >
          <Tag style={{ cursor: "pointer" }}>{value.length} Ürün</Tag>
        </Dropdown>
      ),
    },
    {
      title: "Hediye mi?",
      dataIndex: "isGift",
      key: "isGift",
      filters: constants?.yesNo,
      filterMultiple: false,
      render: (value: boolean) => (
        <Tag color={value ? "green" : "red"}>{value ? "Evet" : "Hayır"}</Tag>
      ),
    },
    {
      title: "Aktif mi?",
      dataIndex: "isActive",
      key: "isActive",
      filters: constants?.yesNo,
      filterMultiple: false,
      render: (value: boolean) => (
        <Tag color={value ? "green" : "red"}>{value ? "Evet" : "Hayır"}</Tag>
      ),
    },
  ];

  if (isAdmin()) {
    columns.splice(1, 0, {
      title: "Kullanıcı",
      dataIndex: "user",
      key: "user",
      fixed: true,
      render: (value: any) => value.fullName,
    });
    columns.splice(10, 0, {
      title: "Etiketler",
      dataIndex: "labels",
      key: "labels",
      render: (value: any[]) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items: value.map((label, index) => ({
              key: index,
              label: label.name,
            })),
          }}
        >
          <Tag style={{ cursor: "pointer" }}>{value.length} Etiket</Tag>
        </Dropdown>
      ),
    } as any);
  }

  return columns;
};

export const getCouponCPFormItems = (
  productOptions: any[],
  constants: any,
  form?: FormInstance,
  isEdit = false
) => {
  return (
    <>
      {isEdit && (
        <>
          <Form.Item name="id" rules={[required]} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label="Kupon Kodu"
            name="code"
            rules={[required, min(2), max(255)]}
          >
            <Input />
          </Form.Item>
        </>
      )}
      <CouponIsGiftForm />
      <Form.Item name="useLimit" label="Kullanım Limiti">
        <InputNumber min={0} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="startDate" label="Başlangıç Tarihi">
        <ZDatePicker showTime />
      </Form.Item>
      <Form.Item name="finishDate" label="Bitiş Tarihi">
        <ZDatePicker showTime />
      </Form.Item>
      <CouponAgainUseTypeForm
        form={form}
        againUseTypes={constants?.againUseTypes}
      />
      <Form.Item name="isLimitPassive" valuePropName="checked">
        <Checkbox>
          <Tooltip title="Kullanım süresinin yarısı dolmadan satma vs gibi tüm satış limitlerini kaldırır.">
            Satın alma süre limitlerini kaldır
          </Tooltip>
        </Checkbox>
      </Form.Item>
      <Form.Item name="product" label="Uygulanacağı Ürünler">
        <Select allowClear mode="multiple" options={productOptions} />
      </Form.Item>
      <Form.Item
        name="productPeriods"
        label="Hangi Paketlerde Kullanılamasın ?"
      >
        <Select
          allowClear
          mode="multiple"
          options={constants?.productPeriods}
        />
      </Form.Item>
      <Form.Item name="labels" label="Etiketler">
        <SearchSelect
          multiple
          searchAction={(searchValue, setOptions, setLoading) =>
            LabelCrudApi.getAll(true, 1, 10, searchValue)
              .then((response) => {
                setOptions(getOptionsFromEntities(response["hydra:member"]));
              })
              .finally(() => setLoading(false))
          }
        />
      </Form.Item>
      <Form.Item
        name="isActive"
        label="Aktif mi?"
        rules={[required]}
        initialValue={true}
      >
        <Select
          options={[
            { label: "Evet", value: true },
            { label: "Hayır", value: false },
          ]}
        />
      </Form.Item>
    </>
  );
};
