import { useContext, useEffect, useRef, useState } from "react";
import { UserCrudApi } from "../../client/EntityApi";
import CrudTable, {
  ICrudTableRefMethods,
} from "../../components/crud/CrudTable";
import CrudTableProcessButton from "../../components/crud/CrudTableProcessButton";
import {
  getUserCPColumns,
  getUserCPFormItems,
} from "../../components/hook/UserCPComponent";
import CouponAddModal, {
  ICouponAddModalRefMethods,
} from "../../components/modal/CouponAddModal";
import { ConstantContext } from "../../contexts";
import {
  FileAddOutlined,
  EyeOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import DefaultBreadcrumb from "../../components/common/DefaultBreadcrumb";
import PaymentAddModal, {
  IPaymentAddModalRefMethods,
} from "../../components/modal/PaymentAddModal";
import { FormInstance, message } from "antd";
import CustomCard from "../../components/card/CustomCard";

const UserCrudPage = () => {
  const navigate = useNavigate();
  const couponAddModalRef = useRef<ICouponAddModalRefMethods>(null);
  const paymentAddModalRef = useRef<IPaymentAddModalRefMethods>(null);
  const crudPageRef = useRef<ICrudTableRefMethods>(null);
  const constantContext = useContext(ConstantContext);

  const [addFormInstance, setAddFormInstance] = useState<FormInstance>();
  const [editFormInstance, setEditFormInstance] = useState<FormInstance>();

  useEffect(() => {
    if (crudPageRef?.current) {
      setAddFormInstance(crudPageRef.current.addFormInstance);
      setEditFormInstance(crudPageRef.current.editFormInstance);
    }
  }, [crudPageRef]);

  const beforeOperation = (values: any) => {
    values.roles = [values.roles];
    return values;
  };

  return (
    <>
      <DefaultBreadcrumb items={[{ title: "Kullanıcı Listesi" }]} />
      <CustomCard>
        <CrudTable
          ref={crudPageRef}
          entityLabel="Kullanıcı"
          api={UserCrudApi}
          columns={getUserCPColumns(constantContext)}
          extraTitleProcess={() => (
            <CrudTableProcessButton
              tooltipText="Kupon Ekle"
              icon={<FileAddOutlined />}
              onClick={() => couponAddModalRef.current?.setOpen(true)}
            />
          )}
          extraRowProcess={(row) => (
            <>
              <CrudTableProcessButton
                tooltipText="Kullanıcı Detayı"
                icon={<EyeOutlined />}
                onClick={() => navigate("/kullanicilar/" + row.id)}
              />
              <CrudTableProcessButton
                tooltipText="Ödeme Oluştur"
                icon={<CreditCardOutlined />}
                onClick={() => {
                  paymentAddModalRef.current?.form.setFieldValue(
                    "user",
                    row["@id"]
                  );
                  paymentAddModalRef.current?.setOpen(true);
                }}
              />
            </>
          )}
          addFormItems={getUserCPFormItems(
            constantContext?.roles,
            addFormInstance
          )}
          editFormItems={getUserCPFormItems(
            constantContext?.roles,
            editFormInstance,
            true
          )}
          beforeAddOperation={(values) => beforeOperation(values)}
          beforeEditOperation={(values) => beforeOperation(values)}
          setEditFields={(row) => ({ roles: row.roles?.[0] ?? "ROLE_USER" })}
        />
        <CouponAddModal ref={couponAddModalRef} />
        <PaymentAddModal
          ref={paymentAddModalRef}
          afterCreate={() => {
            crudPageRef.current?.refreshData();
            message.success("Ödeme oluşturuldu.");
          }}
        />
      </CustomCard>
    </>
  );
};

export default UserCrudPage;
