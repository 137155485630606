import { isAdmin } from "../../helpers/AuthHelper";
import { getConstantValue } from "../../helpers/UtilHelper";

export const getRecordCPColumns = (recordStates: any[]) => {
  let columns = [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Kupon Kodu",
      dataIndex: "coupon",
      key: "coupon",
      render: (value: any) => value.code,
    },
    {
      title: "Durum",
      dataIndex: "state",
      key: "state",
      filters: recordStates,
      filterMultiple: false,
      render: (value: any) => getConstantValue(value, recordStates),
    },
    {
      title: "Hakediş Tutarı",
      dataIndex: "paymentTotal",
      key: "paymentTotal",
      sorter: true,
      render: (value: any) => value + " ₺",
    },
    {
      title: "Ödeme Tutarı",
      dataIndex: "sentimentProductPrice",
      key: "sentimentProductPrice",
      render: (value: any) => value + " ₺",
    },
    {
      title: "Ürün",
      dataIndex: "product",
      key: "product",
      render: (value: any) => value?.name ?? "-",
    },
  ] as any[];

  if (isAdmin()) {
    columns.splice(1, 0, {
      title: "Kullanıcı",
      dataIndex: "user",
      key: "user",
      render: (_: any, row: any) => row.coupon.user.fullName,
    });
  }

  return columns;
};
